import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import styled from "styled-components"
import { Seo } from "../strapi/Seo"
import { GlobalStyles } from "../GlobalStyles"
import { PageContext } from "../contexts/PageContext"
import { SectionRenderer } from "../sections/SectionRenderer"
import { StrapiSideMenu } from "../strapi/StrapiSideMenu"
import { Sidebar } from "../components/Sidebar"
import { theme } from "twin.macro"

import "../styles.css"

const EmptySidebar = styled(Sidebar)`
  @media (min-width: ${theme("screens.lg")}) {
    position: sticky;
    top: 0;
  }
`

export default function EmptyPage({ pageContext, data }) {
  const hasSideMenu = !!data.strapi?.page?.sideMenu?.id
  const allSections = data.strapi.page?.sections || []
  const sections = allSections.map((data, index) => (
    <React.Fragment key={`${data.__typename}__${data.id}`}>
      <SectionRenderer data={data} />
    </React.Fragment>
  ))

  return (
    <PageContext.Provider value={{ ...pageContext, data }}>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, user-scalable=no,initial-scale=1, maximum-scale=1"
        />
      </Helmet>
      <GlobalStyles />
      <Seo />
      <div className="flex lg:space-x-l">
        {hasSideMenu && (
          <div className="flex flex-col lg:block items-start w-full lg:w-1/4">
            <EmptySidebar className="hidden lg:block">
              <StrapiSideMenu id={data.strapi?.page?.sideMenu?.id} />
            </EmptySidebar>
          </div>
        )}
        <div className="flex flex-col w-full min-h-screen">{sections}</div>
      </div>
    </PageContext.Provider>
  )
}

export const query = graphql`
  query EmptyPageData($pageId: ID!) {
    strapi {
      page(id: $pageId) {
        layout
        ...Page
      }
    }
  }
`
